import {
  AnnouncementBlockData,
  ApplicationPrivacyPolicyData,
  ArticleCollectionBlockData,
  BlocksRender,
  ColumnedSectionBlockData,
  ContactCollectionBlockData,
  CtaBlockData,
  ExternalTarget,
  HighlightCollectionBlockData,
  HubSpotFormBlockData,
  ImageContentBlockData,
  InternalTarget,
  LogoCollectionBlockData,
  OperatorCollectionBlockData,
  OrganizationBlockData,
  PageRichText,
  Section,
  SEO,
  ServiceCollectionBlockData,
  StepByStepBlockData,
  TextContentBlockData,
  TextContentCollectionBlockData,
  VideoBlockData,
} from '@wunderdogsw/finbb-dms-theme'
import { graphql, PageProps } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import React from 'react'
import * as blockComponents from '../blocks'
import HeroBlock, { HeroBlockData } from '../blocks/hero-block'
import Layout from '../components/layout/layout'
import useFiApplicationPrivacyPolicy from '../hooks/use-fi-application-privacy-policy'
import useEnApplicationPrivacyPolicy from '../hooks/use-en-application-privacy-policy'

type BlockData =
  | AnnouncementBlockData
  | ArticleCollectionBlockData
  | ColumnedSectionBlockData
  | ContactCollectionBlockData
  | CtaBlockData
  | HighlightCollectionBlockData
  | HubSpotFormBlockData
  | ImageContentBlockData
  | LogoCollectionBlockData
  | OperatorCollectionBlockData
  | OrganizationBlockData
  | ServiceCollectionBlockData
  | StepByStepBlockData
  | TextContentBlockData
  | TextContentCollectionBlockData
  | VideoBlockData

type CallToActionTargetData = ExternalTarget | InternalTarget

type PageData = {
  contentfulPage: {
    afterBlocks: BlockData[]
    beforeBlocks: BlockData[]
    body: RenderRichTextData<ContentfulRichTextGatsbyReference>
    heroBlock: HeroBlockData
    metaDescription: {
      metaDescription: string
    }
    metaImage?: {
      file: {
        url: string
      }
      gatsbyImageData: IGatsbyImageData
    }
    metaTitle
    primaryCallToActionCaption: string
    primaryCallToActionTarget: CallToActionTargetData
    secondaryCallToActionCaption: string
    secondaryCallToActionTarget: CallToActionTargetData
  }
}
type PageContextProps = {
  locale: 'en-US' | 'fi-FI'
}

const Page: React.FC<PageProps<PageData, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const locale = pageContext.locale

  let applicationData: ApplicationPrivacyPolicyData
  if (locale === 'fi-FI') {
    applicationData = useFiApplicationPrivacyPolicy()
  } else {
    applicationData = useEnApplicationPrivacyPolicy()
  }

  const {
    contentfulPage: {
      afterBlocks,
      beforeBlocks,
      body,
      heroBlock,
      metaDescription,
      metaImage,
      metaTitle,
    },
  } = data

  const navbarInverse =
    !heroBlock.isFullScreen ||
    (!heroBlock.isFullScreen && !!heroBlock.backgroundMedia)

  return (
    <Layout locale={pageContext.locale} navbarInverse={navbarInverse}>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        image={metaImage?.file?.url}
      />
      {heroBlock && <HeroBlock data={heroBlock} />}
      {beforeBlocks && (
        <BlocksRender
          applicationData={applicationData}
          components={blockComponents}
          locale={pageContext.locale}
        >
          {beforeBlocks}
        </BlocksRender>
      )}
      {body && (
        <Section.Body>
          <PageRichText>{body}</PageRichText>
        </Section.Body>
      )}
      {afterBlocks && (
        <BlocksRender
          applicationData={applicationData}
          components={blockComponents}
          locale={pageContext.locale}
        >
          {afterBlocks}
        </BlocksRender>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      afterBlocks {
        __typename
        ...Blocks
      }
      beforeBlocks {
        __typename
        ...Blocks
      }
      body {
        raw
        references {
          __typename
          ... on Node {
            ...RichTextReferences
          }
        }
      }
      heroBlock {
        ...HeroBlock
      }
      metaDescription {
        metaDescription
      }
      metaImage {
        file {
          url
        }
        gatsbyImageData(width: 2560)
      }
      metaTitle
    }
  }
`
